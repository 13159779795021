<template>
  <div
    :id="item.name"
    class="game-item effect-hover"
    :class="{ maintain: item.maintain }"
    @click="handleOpenGame(item)"
  >
    <div class="item effect-hover">
      <div class="game-item__wrapper">
        <BaseImg
          :id="`${item?.name}-image`"
          :src="item?.image || item?.img"
          :alt="item?.name"
          lazy
          class="game-item__thumbnail"
          crossorigin="anonymous"
        />
      </div>
      <div class="game-item__hover">
        <div class="game-content">
          <p class="game-play">
            <BaseImg class="image-hover" :src="'/assets/images/components/common/play-game.webp'" alt="play" />
          </p>
        </div>
      </div>
    </div>
    <div
      v-if="!item.maintain && jackpot(item.partner_game_id)"
      class="game-item__money"
    >
      <div class="money-content">
        <BaseImg :src="'/assets/images/components/common/icon-jackpot.svg'" alt="iconsPrice" />
        <AnimatedNumber
          :number="jackpot(item.partner_game_id)"
        />
      </div>
    </div>
    <!-- <CommonBaseImg v-if="item.maintain" class="game-item__maintain" :src="CASINO_PATH + 'tag-maintain.svg'" /> -->
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia'
import BaseImg from '~/components/common/base-img.vue'
import AnimatedNumber from '~/components/common/animate-number.vue'
import useNavigationGame from '~/composables/game/useNavigationGame'
import { PATH_IMAGES } from '~/constants/path'
import { useAppStore } from '~/store/app'

const { $device, $pinia } = useNuxtApp()

const store = useAppStore($pinia)
const { currentUser } = storeToRefs(store)

const props = defineProps({
  item: {
    type: Object,
    default: () => {
    }
  }
})
const jackpot = (gameid) => {
  if (gameid) {
    return store.jackpot[gameid]
  }
  return null
}
const { navigationCheckLoggedInAndOpenGame } = useNavigationGame()

const handleOpenGame = (item) => {
  navigationCheckLoggedInAndOpenGame(item)
}

const getThumbImage = () => {
  if (props.item.tags?.toLowerCase() === 'sự kiện' || props.item.tags?.toLowerCase() === 'event') {
    return PATH_IMAGES + 'label-event.webp'
  }
  if (props.item.tags?.toLowerCase() === 'new') {
    return PATH_IMAGES + 'label-new.webp'
  }
  if (props.item.tags?.toLowerCase() === 'hot') {
    return PATH_IMAGES + 'label-hot.webp'
  }
  return ''
}
</script>

<style scoped src="assets/scss/components/common/games/item-game/index.scss" />
